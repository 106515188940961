// import styled from "styled-components";
import GlobalStyle from './components/Style/GlobalStyle';
import styled from "styled-components";
import { BrowserRouter as Router, Routes, Route, NavLink, useParams } from "react-router-dom"

// import { UserProvider } from './Components/Context/UserContext';

import ShopContextProvider from './components/context/ShopContext';

import ShunkShop_CartWidget from './components/shop/ShunkShop_CartWidget';
import ShunkShop_HomeWidget from './components/shop/ShunkShop_HomeWidget';

import HomePage from "./components/HomePage_v2";
import ShowPage from './components/ShowPage';
import BandPage from './components/BandPage';
import Shop from './components/shop/Shop';
import NewYorkRatKing_Jan2024 from './components/Secret_Pages/NewYorkRatKing_Jan2024';
import TapeGhost from './components/DentalRecords/TapeGhost/TapeGhost';


// import PriceMaster from './components/PriceMaster';
// import Archive from './components/Archive';
// import DentalRecords from "./components/DentalRecords";
// import ThreeDim from './components/ThreeDim';
// hey - addition - client


function App() {
  return (
    <AppWrapper>
      <GlobalStyle/>
      <ShopContextProvider>
        <Router>
          {/* <ShunkShop_HomeWidget/> */}
          {/* <ShunkShop_CartWidget/> */}
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/band' element={<BandPage/>} />
            <Route path='/show/:which' element={<ShowPage/>} />
            <Route path='/shop' element={<Shop/>} />
            <Route path='/store' element={<Shop/>} />
            <Route path='/secret-pages/new-york-ratking-roughmix-7fosfjciowefejocjqwe7afijifayfsdjx' element={<NewYorkRatKing_Jan2024/>} />
            <Route path='/dental-records/tapeghost/:MX' element={<TapeGhost/>} />
            <Route path="*" element={<>You done Shunkled onto an empty page... <NavLink to={'/'}>go back</NavLink></>} />
            {/* <Route path='/pricemaster' element={<PriceMaster/>} /> */}
            {/* <Route path='/archive' element={<Archive/>} />
            <Route path='/DentalRecords' element={<DentalRecords/>} />
            <Route path='/three' element={<ThreeDim/>} /> */}
          </Routes>
        </Router>
      </ShopContextProvider>
    </AppWrapper>
  );
}

const AppWrapper = styled.div`
  min-height: 100vh;
  height: fit-content;
  width: 100vw;
  overflow-x: hidden;
  /* overflow-y: scroll; */
`

export default App;
